import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import format from "date-fns/format";

// hooks and cart
import { cartContext } from "providers/CartProvider";

import Layout from "components/Layout";
import SEO from "components/Seo";
import ScheduleItem from "components/ScheduleItem";

import {
  ClassCost,
  MonthlyScheduleGroup,
  MonthlyScheduleList,
  MonthlyScheduleHeader,
} from "styles/styles";

const CcwSchedulePage = () => {
  const [scheduleData, setScheduleData] = useState([]);
  const siteContext = useContext(cartContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${process.env.GATSBY_API_URL}/courses/schedules`,
          {
            validateStatus(status) {
              return status < 500; // Resolve only if the status code is less than 500
            },
          }
        );

        // console.log("Main -> response.data", response.data);
        setScheduleData(response.data);
      } catch (e) {
        siteContext.makeToast("Unable to fetch schedule data.", "error");
      }
    }

    fetchData();
  }, []);

  // const pgClasses = data.pgClasses.nodes ? data.pgClasses.nodes : [];

  const slug = "ccw";

  const classesInCategory = scheduleData.filter(x => {
    const catSlugs = x.coursecats.map(c => c.slug);
    return catSlugs.includes(slug);
  });
  // console.log("ClassSchedulePage -> classesInCategory", classesInCategory);

  const formattedClasses =
    classesInCategory && classesInCategory.length > 0
      ? classesInCategory.map(cl => {
          // const { date, datestop, timestart, timeend, id__normalized } = cat;
          const { date } = cl;
          const rawDate = Date.parse(date);

          const year = format(rawDate, "yyyy");
          const month = format(rawDate, "MMM");
          const day = format(rawDate, "dd");

          return {
            year,
            month,
            day,
            ...cl,
          };
        })
      : [];

  const classesByMonth = formattedClasses.reduce((acc, curr) => {
    const theMonthNum = format(Date.parse(curr.date), "M");
    const theYear = curr.year;

    const matchItem = acc.find(
      x => x.year === theYear && x.monthNum === theMonthNum
    );

    // new month/year
    if (!matchItem) {
      const tempMonthObj = {
        monthNum: theMonthNum,
        monthAbbrev: curr.month,
        year: theYear,
        classes: [curr],
      };

      return acc.concat(tempMonthObj);
    }

    // otherwise, just add to an existing month/year item
    const newClasses = matchItem.classes.concat(curr);
    matchItem.classes = newClasses;

    return acc;
  }, []);

  const cartState = useContext(cartContext);

  const addToCart = (scheduleID, qty = 1) => {
    const attendeesArr = [];
    for (let z = 0; z < qty; z++) {
      attendeesArr.push({
        name: "",
        email: "",
      });
    }

    const newItem = {
      id: scheduleID,
      attendees: attendeesArr,
      qty,
    };

    cartState.updateProducts(newItem);
  };

  return (
    <Layout pageInfo={{ pageName: slug }}>
      <SEO title="Concealed Handgun Permit Training/CCW" />
      <section className="class-main-details">
        <Container>
          <Row>
            <Col>
              <h2>Concealed Handgun Permit Training</h2>

              <p className="font-weight-bold" style={{ fontSize: "20px" }}>
                Virginia Code § 18.2-308.02. (Effective until January 1, 2021)
                Application for a concealed handgun permit; Virginia resident or
                domiciliary. <br />
                <br />
                <span style={{ fontStyle: "italic" }}>
                  B. The court shall require proof that the applicant has
                  demonstrated competence with a handgun <b>in person</b>
                </span>
              </p>

              <Row>
                <Col xs="12" md="6">
                  <h4 className="subhead pb-2 mb-2 mt-4">Class Covers:</h4>
                  <ul>
                    <li>Handgun Handling</li>
                    <li>Firearms Maintenance</li>
                    <li>Marksmanship</li>
                    <li>Fundamental</li>
                    <li>Skill Drills</li>
                    <li>Followed by competence qualification at the range</li>
                  </ul>
                </Col>

                <Col xs="12" md="6">
                  <h4 className="subhead pb-2 mb-2 mt-4">Cost Includes:</h4>
                  <ul>
                    <li>All training under "Class Covers" section</li>
                    <li>Class materials</li>
                    <li>Use of our handgun and holster</li>
                    <li>Ammunition</li>
                    <li>Range fees</li>
                  </ul>
                </Col>
              </Row>

              <ClassCost>
                <h3 className="subhead pb-2 mb-1">Cost:</h3>
                <p>$250</p>
              </ClassCost>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bluedark">
        <Container>
          <Row>
            <Col>
              <div className="schedule">
                <h3>Class Schedule: Concealed Handgun Permit Training</h3>

                {classesByMonth &&
                  classesByMonth.map((monthData, i) => {
                    if (monthData.classes.length > 0) {
                      return (
                        <MonthlyScheduleGroup key={`month-${i}`}>
                          <MonthlyScheduleHeader>
                            <h4 className="subhead">
                              {`${monthData.monthAbbrev} ${monthData.year}`}
                            </h4>
                          </MonthlyScheduleHeader>

                          <MonthlyScheduleList>
                            {scheduleData.length > 0 && monthData.classes ? (
                              monthData.classes.map((item, z) => (
                                <li key={z}>
                                  <ScheduleItem
                                    item={item}
                                    addToCart={addToCart}
                                    cartState={cartState}
                                  />
                                </li>
                              ))
                            ) : (
                              <p>No classes currently scheduled.</p>
                            )}
                          </MonthlyScheduleList>
                        </MonthlyScheduleGroup>
                      );
                    }
                    return null;
                  })}

                {!scheduleData && (
                  <p>
                    No upcoming classes scheduled in this category. Check back
                    soon!
                  </p>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default CcwSchedulePage;
